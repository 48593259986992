<template>
  <div>
  <GetQrcode
      v-if="qrData.isQrCodeShown"
      @success="gotQrCode"
      @closeScan="closeScan"
    />
  <div class="main" v-if="!qrData.isQrCodeShown">
    
    <van-search
      v-model="query.value"
      shape="round"
      background="#ffffff"
      placeholder="请输入搜索关键词"
      show-action
    >
    <template #action>
      <div @click="onClickScan">扫码</div>
    </template>
    </van-search>

    <div class="tabsBox" >
      <van-tabs v-model="active" swipeable type="card" color="#0F60A7">
        <van-tab title="全部应用" name="1">
          <van-collapse v-model="activeNames">
            <van-collapse-item
              v-for="(item, index) in menulist"
              :key="index"
              :name="item.index"
              :title="item.title"
            >
              <div
                class="flex alignCenter rP-10"
                v-for="(items, i) in item.child"
                :key="i"
              >
                <van-cell
                  :to="items.index"
                  :title="items.title"
                  :icon="'ehsq-' + items.index"
                ></van-cell>
                <van-icon
                  name="star"
                  :color="items.is_star == 1 ? '#ee871a' : '#e1e1e1'"
                  @click="setStar(items)"
                />
              </div>
            </van-collapse-item>
          </van-collapse>
        </van-tab>
        <van-tab title="我关注的" name="2">
          <div class="flex flex-warp tP-40" v-if="followList.length > 0">
            <div
              class="flex flex-warp justifyCenter w-p-33"
              v-for="(item, index) in followList"
              :key="index"
              @click="router.push('/' + item.index)"
            >
              <div
                class="w-65 h-65 flex alignCenter justifyCenter radius-5 ehsq-icon "
                :class="'van-icon-ehsq-' + item.index"
              ></div>
              <div class="flex alignCenter justifyCenter w-p-100 h-35">
                {{ item.name }}
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</div>
</template>
<script>
// /* eslint-disable */
import { reactive, watch, onMounted, ref } from "vue";
import http from "../../../api/http";
import { Dialog } from "vant";
import GetQrcode from './GetQrcode.vue';
import { useRouter } from "vue-router";
import { ElMessage } from 'element-plus';
export default {
  components:{GetQrcode},
  setup() {
    const router = useRouter();
    const query = reactive({
      value: ""
    });
    const active = ref("");
    const activeNames = ref(["epp"]);

    const followList = ref([{ name: "现场管理", index: "siteManage" }]);
    // 监听
    watch(
      () => query.value,
      (newval, oldval) => {
        console.log(oldval, newval);
        // if(newval == ''){

        // }else{

        // }
      }
    );
    const menulist = ref([]);
    const getMenuList = () => {
      http
        .get({
          method: "query",
          queryId: 1075
        })
        .then(res => {
          var list = res.map[0].child;
          menulist.value = res.map[0].child;
          list.forEach(element => {
            activeNames.value.push(element.index);
          });
        });
    };
    const getStarList = () => {
      http
        .get({
          method: "query",
          queryId: 1073
        })
        .then(res => {
          console.log(res);
          followList.value = res.map;
        });
    };
    const setStar = item => {
      Dialog.confirm({
        title: "提示",
        message:
          item.is_star == 1
            ? "此操作将取消菜单收藏，请确认？"
            : "此操作将收藏所选菜单，请确认？"
      }).then(() => {
        http
          .post({
            method: "query",
            queryId: 1072,
            index: item.index,
            name: item.title,
            meta: "{}",
            url_path: ""
          })
          .then(() => {
            getMenuList();
            getStarList();
          });
      });
    };
    const collapse = ref(null);

    const qrData = reactive({
      qrCode: "",
      isQrCodeShown: false,
      isQrAlert: false,
      qrCOdeData: "",
    });
    // 检查当前登录设备类型
    const checkDevice = () => {
      // 获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      const info = navigator.userAgent;
      // 通过正则表达式的test方法判断是否包含“Mobile”字符串
      const isMobile = /mobile/i.test(info);
      // 如果包含“Mobile”（是手机设备）则返回true
      return isMobile;
    };
    const onClickScan = () =>{
      const isMobile = checkDevice();
      if (isMobile === true) {
        qrData.isQrCodeShown = true;
      }
    }

    // 扫码成功
    const gotQrCode = (params) => {
      // 这里params就是二维码的内容，这里可以根据自己项目的需求处理内容
      if (params) {
        // qrData.isQrAlert = true;
        // ElMessage({message:params,type:'success'});
        qrData.qrCOdeData = params;  // 我这里拿到二维码的内容是弹出框弹出，为了测试
        qrData.isQrCodeShown = false;
        router.push('/personInfo/'+params);
      }
    };

    const closeScan = () => {
      // 开发的 时候为了方便，加了这个关闭按钮，正常项目可以去掉
      qrData.isQrCodeShown = false;
    };
    onMounted(() => {
      getStarList();
      getMenuList();
    });
    return {
      router,
      query,
      active,
      activeNames,
      followList,
      setStar,
      getStarList,
      menulist,qrData,
      collapse,onClickScan,closeScan,gotQrCode
    };
  }
};
</script>
<style scoped>
.tabsBox {
  height: calc(100% - 55px);
  width: 100%;
  overflow-y: auto;
}
</style>
